<template>
    <div class='container'>
        <div class="contentBox">
            <div class="leftBox">
                <p>『 {{ info.title || '' }} 』</p>
            </div>
            <div class="rightBox">
                <!-- <div class="textBox">
                    <div class="title">关于我:</div>
                    <p>我是阿东，艺术创意总监，倾沫摄影工作室创始人。<br>服务项目：品牌策划、电商视觉策划、产品商业摄影、人像写真、短视频拍摄。</p>
                    <p>我是阿东，艺术创意总监，倾沫摄影工作室创始人。<br>服务项目：品牌策划、电商视觉策划、产品商业摄影、人像写真、短视频拍摄。</p>
                </div>
                <div class="textBox">
                    <div class="title">品牌故事:</div>
                    <p>我是阿东，艺术创意总监，倾沫摄影工作室创 </p>
                </div> -->
                <div v-html="info.content || ''" class="content"></div>
                <ul style="margin-top: 60px">
                    <li v-for="(item, i) in info.imgBox" :key="i">
                        <img :src="item" alt="" style="border-radius: 10px;">
                    </li>
                </ul>
            </div>
        </div>
        <bottomPage></bottomPage>
    </div>
</template>

<script>
import bottomPage from "@/components/bottomPage.vue";
export default {
    name: 'briefIntroduction',
    components: { bottomPage },
    data() {
        return {
            info: {}
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getInfo()
    },
    methods: {
        getInfo() {
            this.post('Config/aboutUs').then(res => {
                console.log(res, 'list');
                if (res.code == 200) {
                    // res.data.image1 = this.config.config + res.data.img1
                    // res.data.image2 = this.config.config + res.data.img2
                    res.data.imgBox = [this.config.config + res.data.img1, this.config.config + res.data.img2]
                    this.info = res.data

                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    },
}
</script>
<style lang='less' scoped>
.container {
    overflow-y: auto;
    scrollbar-width: none;
    height: calc(100vh - 64px);
}

.contentBox {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 20px 1rem;
    width: 80%;
    margin: 0 auto;
    scrollbar-width: none;

    div {
        flex: 0 0 46%;
        max-width: 550px;
    }

    .leftBox {
        text-align: center;
        font-size: 4rem;
        padding-left: 1rem;
        margin: 2.5rem;
        font-weight: bold;

        p {
            font-size: 1.8rem !important;
            color: #333;
        }
    }

    .rightBox {
        text-align: left;
        padding-top: 50px;

        .textBox {
            color: #636b79;
            font-size: 16px;

            .title {
                color: #485060;
                font-weight: bold;
                margin-bottom: 0.125rem;
            }

            p {
                margin-bottom: 1rem;
            }
        }

        ul {
            display: grid;
            column-gap: 18px;
            grid-template-columns: repeat(2, 1fr);

            li {
                list-style: none;
                display: inline;

                img {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .contentBox {
        display: block;

        .leftBox {
            text-align: left;
        }
    }
}
</style>