<template>
  <div id="app">
    <Navigation></Navigation>
    <router-view></router-view>
    <!-- <bottomPage></bottomPage> -->
  </div>
</template>

<script>
import Navigation from "./components/NavigationPage.vue"
// import bottomPage from "./components/bottomPage"
export default {
  name: 'App',
  components: {
    Navigation,
    // bottomPage,
  },
  data() {
    return {

    };
  },

}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: rgb(245, 249, 252);
  min-width: 300px;
  // max-width: 1500px;
  // margin:  0 auto;
}
</style>
