<template>
    <div class='content'>
        <ImageAndText :showTag="true" :tabList="tabList" @changeTab="changeTab" :list="list"></ImageAndText>
        <div class="pageBox" v-if="total > 20">
            <div class="button" @click="nextPage" v-if="page == 1">下一页</div>
            <div v-else>
                <el-pagination layout="prev, pager, next" :total="total" :page-size="20" background
                    @current-change="handleCurrentChange" :current-page="page">
                </el-pagination>
            </div>
        </div>
        <bottomPage></bottomPage>
    </div>
</template>

<script>
import ImageAndText from "../components/ImageAndText.vue"
import bottomPage from "@/components/bottomPage.vue";
export default {
    name: 'AIGG',
    components: {
        ImageAndText, bottomPage
    },
    data() {
        return {
            tabList: [],
            id: null,
            page: 1,
            list: [],
            total: 0,
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getTabList()
    },
    mounted() {
    },
    methods: {
        getTabList() {
            this.post('Aicg/getCategory').then(res => {
                if (res.code == 200) {
                    this.tabList = res.data || []
                    console.log(this.tabList, 'tabList');
                    this.id = res.data[0].id || null
                    this.getData()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        changeTab(index, item) {
            console.log(index, item, 'oiiiiii');
            this.id = item.id
            this.getData()
        },
        getData() {
            this.post('Aicg/getList', {
                cate_id: this.id,
                page: this.page,
                size: 20,
            }).then(res => {
                if (res.code == 200) {
                    res.data.list.filter(item => {
                        item.image = this.config.config + item.cover
                    })
                    this.total = res.data.total
                    console.log(res, 'rereerrre');
                    let info = {
                        list: res.data.list
                    }
                    this.list = []
                    this.list.push(info)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        nextPage() {
            this.page = 2
            this.getData()
        },
        handleCurrentChange(e) {
            console.log(e, 'gbym');
            this.page = e
            this.getData()
        }
    },
}
</script>
<style lang='less' scoped>
.content {
    height: calc(100vh - 64px);
    overflow-y: auto;
}

.pageBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    color: #fff;
    padding: 0.2rem 0.5rem;
    width: 5rem;
    text-align: center;
    border-radius: 20px;
    background: rgb(14, 23, 44);
}

/deep/ .el-pagination {
    background: transparent;
}

/deep/ .el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    background: transparent !important;
}

/deep/ .el-pagination.is-background .btn-prev:disabled,
.el-pagination.is-background .el-pager li.disabled {
    color: #333 !important;
}

/deep/ .el-pagination.is-background .btn-next,
.el-pagination.is-background .el-pager li {
    background: transparent !important;
}

/deep/ .el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .el-pager li.disabled {
    color: #333 !important;
}

/deep/ .el-pagination .el-pager li {
    background: transparent !important;
    padding: 0 10px;
    border-radius: 12px !important;
}

/deep/ .el-pagination .el-pager li:hover {
    color: rgb(14, 23, 44) !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: rgb(14, 23, 44) !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active :hover {
    color: #fff !important;
}
</style>