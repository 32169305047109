// colors.js
const colors = {
    primary: '#3f51b5',
    secondary: '#ff4081',
    success: '#4caf50',
    warning: '#ffeb3b',
    info: '#2196f3',
    error: '#f44336',
    primary1: '#6E7380',
    primary2: '#0e172c',
};

const ColorsPlugin = {
    install(Vue, options) {
        console.log(Vue, options, 'options')
        Vue.prototype.$colors = colors;
    }
};

export default ColorsPlugin;