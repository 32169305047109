<template>
    <div class='container'>
        <div class="contentBox">
            <div class="tabBox" v-if="showTag">
                <ul>
                    <li v-for="(item, index) in tabList" :key="index" @click="changeTab(index)" :style="{
                        borderColor: active == index ? '#333' : ''
                    }">{{ item.text }}</li>
                </ul>
                <div class="line"></div>
            </div>
            <div v-else>
                <h3 class="title" style="margin-top: 70px;margin-bottom: 30px;">{{ info.title || '' }}</h3>
                <div class="introduce" style="margin-bottom: 30px;">{{ info.intro || '' }}</div>
            </div>
            <!-- <img :src="info.image" alt="" style="width: 80%;margin: 25px auto;"> -->
            <div v-html="info.content || ''" class="content"></div>
        </div>
        <bottomPage></bottomPage>
    </div>
</template>

<script>

import bottomPage from "@/components/bottomPage.vue";
export default {
    name: 'photoDetailes',
    components: { bottomPage },
    data() {
        return {
            active: 0,
            tabList: [
                // {
                //     text: "全部",
                //     index: 0,
                // }, {
                //     text: "人像",
                //     index: 1,
                // }, {
                //     text: "产品",
                //     index: 2,
                // }, {
                //     text: "插画",
                //     index: 3,
                // }
            ],
            activeItem: null,
            maskText: '',
            richContent: '<p>这是一个<strong>富文本</strong>内容的例子。',
            id: null,
            type: null,
            info: {},
        };
    },
    props: {
        showTag: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.id = this.$route.query.id;
        this.type = this.$route.query.type
        if (this.type == 2) {
            this.post('Aicg/getDetail', {
                id: this.id
            }).then(res => {
                console.log(res, 'resresres');
                if (res.code == 200) {
                    res.data.image = this.config.config + res.data.img
                    this.info = res.data
                } else {
                    this.$message.error(res.msg)
                }
            })
        } else {
            this.post('Photo/photographyInfo', {
                id: this.id
            }).then(res => {
                console.log(res, 'resresres');
                if (res.code == 200) {
                    res.data.image = this.config.config + res.data.img
                    this.info = res.data
                } else {
                    this.$message.error(res.msg)
                }
            })
        }
    },
    methods: {
        showMasker(index) {
            this.activeItem = index
            // console.log(123, index);
        },
        changeTab(index) {
            this.active = index
        },
        getDetailes() {
            if (this.showTag) {
                this.$router.push('/photoDetailes')
            }
        }
    },
}
</script>
<style lang='less' scoped>
.container {
    padding: 20px;
    max-width: 1280px;
    margin: 0 auto;
    height: calc(100vh - 104px);
    overflow-y: auto;
    scrollbar-width: none;

    li {
        list-style: none;
    }

    .contentBox {
        min-height: calc(100vh - 304px);
        scrollbar-width: none;

        .title {
            font-size: 1.75rem;
        }

        .introduce {
            font-size: 1.0625rem;
            margin: 0.4rem;
        }

        .tabBox {
            position: relative;
            font-size: 0.875rem;
            color: #636b79;

            ul {
                display: flex;

                li {
                    margin: 10px;
                    padding: 10px 0;
                    border-bottom: 2px solid transparent;
                    z-index: 2;
                }
            }

            .line {
                width: 100%;
                border-bottom: 2px solid #d7dce1;
                position: absolute;
                bottom: 10px;
                z-index: 1;
            }
        }

        .content {
            margin-top: 3rem;
        }
    }
}


@keyframes scaleSize {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}
</style>