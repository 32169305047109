import Vue from 'vue'
import App from './App.vue'
import './assets/iconfont/iconfont.css'
import ColorsPlugin from './utils/color.js';
import router from './utils/router.js'; // 引入router配置
import "../src/assets/form.css" 
import config from './config/config';
import { get,post} from "./utils/request"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ColorsPlugin); 
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.config = config
Vue.prototype.get = get
Vue.prototype.post = post
Vue.prototype.webInfo = Vue.observable({   });
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
