import axios from 'axios';
import config from '@/config/config';
// 创建axios实例
const service = axios.create({
  baseURL: config.config+'/api.php/', // API的base_url
  timeout: 5000 ,// 请求超时时间
  headers:{
    'content-type': 'application/x-www-form-urlencoded',
    token:"sf"
  }
});
 
// 请求拦截器
// service.interceptors.request.use(
//   config => {
//     // 可以在这里添加请求头等信息
//     config.headers['Content-Type']="application/x-www-form-urlencoded"
//     config.headers['Authorization'] = `sf`;
//     return config;
//   },
//   error => {
//     // 请求错误处理
//     return Promise.reject(error);
//   }
// );
 
// 响应拦截器
service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);
 
// 封装get方法
export function get(url, params) {
  return service.get(url, {
    params: params
  });
}
 
// 封装post方法
export function post(url, data) {
  return service.post(url, data);
}
  