<template>
    <div class='bottomContainer'>
        <ul>
            <li v-for="(item, index) in list" :key="index" @click="$router.push('/')">{{ item.title }}</li>
        </ul>
        <p>
            {{ info.copyright || '' }} 管理员：{{ info.email || '' }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'bottomPage',
    components: {},
    data() {
        return {
            info: {},
            list: [
                { title: '网站地图', },
                { title: '使用规章', },
                { title: '隐私政策', },
                { title: '电子公告', },
            ]
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getInfo()
    },
    methods: {
        getInfo() {
            this.post('Config/base').then(res => {
                console.log(res, '666');
                if (res.code == 200) {
                    this.info = res.data

                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    },
}
</script>
<style lang='less' scoped>
.bottomContainer {
    padding: 2rem 0;
    // margin-top: 2rem;
    height: 120px;
    box-sizing: border-box;

    ul {
        width: 40%;
        margin: 0 auto;

        li {
            list-style: none;
            display: inline;
            margin: 20px;
            font-size: 0.825rem;
        }

        li:hover {
            cursor: pointer;
        }
    }

    p {
        font-size: 0.625rem;
        margin-top: 1rem;
    }
}
</style>