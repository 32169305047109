<template>
  <div class='content'>
    <ImageAndText :list="list" />
    <bottomPage></bottomPage>
  </div>
</template>

<script>
import ImageAndText from "../components/ImageAndText.vue"
import bottomPage from "@/components/bottomPage.vue";
export default {
  name: 'eCommerce',
  components: { ImageAndText, bottomPage, },
  data() {
    return {
      list: []

    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.post('Photo/storeCase').then(res => {
        if (res.code == 200) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            res.data.filter(item => {
              if (Array.isArray(item.list) && item.list.length > 0) {
                item.list.filter(info => {
                  info.image = this.config.config + info.img
                })
              }
            })
          }
          this.list = res.data
          // console.log(res.data, 'res.datadatadata');
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
}
</script>
<style lang='less' scoped>
.content {
  height: calc(100vh - 64px);
  overflow-y: auto;
}
</style>