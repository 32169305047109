<template>
    <div class=''>
        <ContentBox :showBigBAckground="true" :sidebarItems="sidebarItems" :contentItems="contentItems"></ContentBox>
    </div>
</template>

<script>
import ContentBox from "../components/ContentBox.vue"
export default {
    name: 'HomePage',
    components: {
        ContentBox
    },
    data() {
        return {
            sidebarItems: [],
            contentItems: []
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getList()
    },
    mounted() {
    },
    methods: {
        getList() {
            this.post('Activity/getList').then(res => {
                // console.log(res, 'list1223');
                if (res.code == 200) {
                    res.data.filter(item => {
                        item.image = this.config.config + item.img
                        if (item.link && item.link.length > 0) {
                            item.link.filter(info => {
                                info.image = this.config.config + info.cover
                                // console.log(info, 'item');
                                return info
                            })
                        }
                        return item
                    })

                    this.sidebarItems = res.data
                    this.contentItems = res.data

                } else {
                    this.$message.error(res.msg);
                }
            })
        },
    },
}
</script>
<style lang='' scoped>
</style>