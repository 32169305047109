<template>
  <div>
    <!-- height: !showBottom ? `calc(100vh - 110px)` : 'calc(100vh - 230px)' -->
    <div class="container" :style="{ height: !showBottom ? '' : '80vh' }">
      <div class="sidebar" @scroll="titlehandleScroll">
        <ul>
          <li v-for="(item, index) in sidebarItems" :key="index" :class="{ active: activeIndex === index }"
            @click="scrollToAnchor(item.id, index)" :ref="`title-${item.id}`" :id="`title-${index}`">
            <!-- <i class="iconfont icon-douyin"></i> -->
            <img :src="item.image" alt="">
            {{ item.title || '' }}
          </li>
        </ul>
      </div>
      <div class="content" @scroll="handleScroll">
        <div class="topBox" style="margin-top: 12px;" v-if="showBigBAckground" :style="{
          backgroundImage: `url(${this.bannerList.adv[0].image || ''})`
        }">
          <div class="topContent">
            <div class="time">{{ nowTime }}</div>
            <div class="dataTime">
              <span>{{ lunarDate.date }}</span>
              <span>{{ lunarDate.week }}</span>
              <span>{{ lunarDate.nowDate }}</span>
            </div>
            <div class="search">
              <form :action="showwebsite.dataUrl" method="get" target="_blank">
                <div class="flex">
                  <div class="leftcontent">
                    <div class="engine h-full lh-0 " @click="showEngines = !showEngines">
                      <div class="flex-shrink-0">
                        <img :src="showwebsite.src">
                      </div>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24"
                          fill="currentColor">
                          <path d="M12 14L8 10H16L12 14Z"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="relative" :style="{
                      display: showEngines ? 'block' : 'none'
                    }">
                      <div class="engines">
                        <ul style="--sw:20">
                          <li :data-url="item.dataUrl" v-for="(item, index) in websiteList" :key="index"
                            @click="changeWebsite(item)">
                            <div class="flex">
                              <div>
                                <img :src="item.src">
                              </div>
                              <div>
                                <span>{{ item.text }}</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="flex-grow-1">
                    <input class="w-full h-full " type="text" placeholder="输入搜索的关键字" autofocus="" autocomplete="off"
                      name="wd">
                  </div>
                  <div class="rightcontent">
                    <button class="btn btn-2 btn-3-md btn-primary" type="submit">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24"
                        fill="currentColor">
                        <path
                          d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z">
                        </path>
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <ul class="Hotsearch">
              <li>热搜榜</li>
              <li class="hostList">
                <div class="text" v-for="(item, i) in this.bannerList.list || []" :key="i" @click="goLink(item)">{{
                  item.logogram || '' }}</div>
              </li>
            </ul>
          </div>
        </div>
        <div v-for="(item, index) in contentItems" :key="index" class="section" :id="`section-${index}`"
          :ref="`item-${item.id}`">
          <h3> {{ item.title }}</h3>
          <ul class="list">
            <li v-for="(info, i) in item.link" :key="i" @click="goLink(info)">
              <div class="info">
                <div class="title">
                  <img :src="info.image" alt=""> {{ info.title || '' }}
                </div>
                <p>{{ info.intro || '' }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-show="showBottom">
      <BottomPage></BottomPage>
    </div>
  </div>
</template>

<script>
import chineseLunar from 'chinese-lunar';

import { Solar, Lunar, HolidayUtil } from 'lunar-javascript';

import BottomPage from './bottomPage.vue';
export default {
  components: {
    BottomPage
  },
  data() {
    return {
      // sidebarItems: [{ id: 1, name: '标题1' }, { id: 1, name: '标题2' }, { id: 1, name: '标题3' }, { id: 1, name: '标题4' }, { id: 1, name: '标题5' }, { id: 1, name: '标题6' }, { id: 1, name: '标题7' }, { id: 1, name: '标题8' }, { id: 1, name: '标题9' }, { id: 1, name: '标题10' }, { id: 1, name: '标题11' }, { id: 1, name: '标题12' }, { id: 1, name: '标题13' }, { id: 1, name: '标题14' }, { id: 1, name: '标题15' }, { id: 1, name: '标题16' }, { id: 1, name: '标题17' }, { id: 1, name: '标题18' }, { id: 1, name: '标题19' }, { id: 1, name: '标题20' },],
      // contentItems: [{ id: 1, name: '内容1' }, { id: 1, name: '内容2' }, { id: 1, name: '内容3' }, { id: 1, name: '内容4' }, { id: 1, name: '内容5' }, { id: 1, name: '内容6' }, { id: 1, name: '内容7' }, { id: 1, name: '内容8' }, { id: 1, name: '内容9' }, { id: 1, name: '内容10' }, { id: 1, name: '内容11' }, { id: 1, name: '内容12' }, { id: 1, name: '内容13' }, { id: 1, name: '内容14' }, { id: 1, name: '内容15' }, { id: 1, name: '内容16' }, { id: 1, name: '内容17' }, { id: 1, name: '内容18' }, { id: 1, name: '内容19' }, { id: 1, name: '内容20' },],
      activeIndex: 0,
      currentDate: '',
      lunarDate: {},
      currentTime: '',
      showEngines: false,
      websiteList: [
        {
          dataUrl: "https://www.baidu.com/s?wd=",
          src: "https://uikit.pagepan.com/7.25/number/d1/79-01/image/baidu.svg",
          text: "百度"
        },
        {
          dataUrl: "https://www.google.com/search?q=",
          src: "https://uikit.pagepan.com/7.25/number/d1/79-01/image/google.svg",
          text: "谷歌"
        },
        {
          dataUrl: "https://www.douyin.com/search/",
          src: "https://uikit.pagepan.com/7.25/number/d1/79-01/image/douyin.svg",
          text: "抖音"
        },
        {
          dataUrl: "https://www.xiaohongshu.com/search_result?keyword=",
          src: "	https://www.qingmoup.com/data/file/%E7%BD%91%E7%AB%99%E9%A1%B5%E9%9D%A2/%E5%9B%BE%E6%A0%87/%E5%B0%8F%E7%BA%A2%E4%B9%A622.svg",
          text: "小红书"
        },
        {
          dataUrl: "https://s.taobao.com/search?commend=all&amp;ie=utf8&amp;initiative_id=tbindexz_20170306&amp;q=",
          src: "https://www.qingmoup.com/data/file/%E7%BD%91%E7%AB%99%E9%A1%B5%E9%9D%A2/%E5%9B%BE%E6%A0%87/%E6%B7%98%E5%AE%9D.svg",
          text: "淘宝"
        }
      ],
      showwebsite: {},
      showBottom: false,
      nowTime: "",
      bannerList: {}
    };
  },
  props: {
    showBigBAckground: {
      type: Boolean,
      default: false
    },
    sidebarItems: {
      type: Array,
      default: () => {
        return []
      }
    },
    contentItems: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  mounted() {
    this.updateDateAndTime()
    this.showwebsite = this.websiteList[0]
    this.getTime()

    // 首页banner
    this.getBanner()
  },
  methods: {
    getTime() {
      setInterval(() => {
        let date = new Date()
        this.nowTime = date.getMinutes() >= 10 ? date.getHours() + ':' + date.getMinutes() : date.getHours() + ':' + '0' + date.getMinutes()
      }, 1000)
    },
    handleScroll(event) {
      // console.log('zx');
      const scrollTop = event.target.scrollTop;
      // console.log(scrollTop, 'scrollTop');
      const offsetTopArray = this.getoffsetTopArray()
      // console.log(offsetTopArray,'offsetTopArray');
      for (var i in offsetTopArray) {
        // 第一格   
        if (scrollTop > 0 && scrollTop < offsetTopArray[0]) {
          this.activeIndex = 0
        } else {
          // console.log(i, scrollTop, offsetTopArray[i - 1], offsetTopArray[i]);
          if (scrollTop > offsetTopArray[i - 1] && scrollTop < offsetTopArray[i]) {
            this.activeIndex = parseInt(i)
            // console.log(i, 'index111',this.contentItems[i]);

            this.scrollTitle(this.contentItems[i].id)
          }
        }
      }
      // console.log(event.target.scrollTop, event.target.clientHeight, event.target.scrollHeight);
      // 监听滚动到底部 
      // if (event.target.scrollTop + event.target.clientHeight > event.target.scrollHeight - 100) {
      //   console.log('已经滚动到底部了');
      //   this.showBottom = true
      // }
      // if (event.target.scrollTop + event.target.clientHeight < event.target.scrollHeight - 100) {
      //   console.log('离开底部了');
      //   this.showBottom = false
      // }
      const scrollDistance = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight;
      if (scrollDistance == 0) {
        this.showBottom = true
      }
      if (scrollDistance > 120) {
        this.showBottom = false
      }
      // if (scrollDistance < 80 && scrollDistance >= 0) {
      //   this.showBottom = true
      // } else if (scrollDistance > 80) {
      //   this.showBottom = false
      // }
    },
    getoffsetTopArray() {
      const array = []
      for (let i = 0; i < this.contentItems.length; i++) {
        const element = document.getElementById(`section-${i}`);
        if (array.indexOf(element.offsetTop) == -1) {
          array.push(element.offsetTop)
        }
      }
      return array
    },
    scrollToAnchor(id, index) {
      console.log(id, index, 'id, index', this.$refs[`item-${id}`], this.$refs[`title-${id}`]);
      // const anchorElement = this.$refs[`item-${id}`][index];
      const anchorElement = this.$refs[`item-${id}`][0];
      console.log(anchorElement, 'anchorElement');
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
      this.scrollTitle(id)
    },
    scrollTitle(id) {
      if (this.contentItems.length && this.contentItems.length > 0) {
        // console.log(this.$refs[`title-${id}`][0], 'ttttitle', this.activeIndex);
        // let num = 5
        // let num = this.contentItems.length / 5
        // console.log(num, 'numnumnum',this.activeIndex, this.contentItems.length );
        for (var i = 0; i < 10; i++) {
          let num = 15 * i
          // console.log(i, 'iiiiiiii', num,num == this.activeIndex);
          if (num == this.activeIndex) {
            console.log(i, 'llllllllllllllllllllllllllliiiiiiii');
            this.$refs[`title-${id}`][0].scrollIntoView({ behavior: 'smooth' });
          }
        }
        // for (var index in 100) {
        //   console.log(index, 'num * index');
        //   if (this.activeIndex == num * index) {
        //     this.$refs[`title-${id}`][0].scrollIntoView({ behavior: 'smooth' });
        //   }
        // }
      }
    },
    updateDateAndTime() {
      const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      const now = new Date();
      this.lunarDate = chineseLunar.solarToLunar(now);


      console.log(Lunar.fromDate(new Date()).toFullString())
      console.log(Solar.fromYmd(2016, 1, 1).toFullString())
      console.log(HolidayUtil.getHoliday(2020, 5, 2) + '')

      var LunarData = Lunar.fromDate(new Date())

      this.lunarDate = {
        date: this.padTimeUnit(now.getMonth() + 1) + '月' + this.padTimeUnit(now.getDate()) + '日',
        time: this.padTimeUnit(now.getHours()) + ':' + this.padTimeUnit(now.getMinutes()),
        week: days[now.getDay()],
        nowDate: LunarData.getMonthInChinese() + '月' + LunarData.getDayInChinese()
      }
    },
    padTimeUnit(unit) {
      return unit < 10 ? '0' + unit : unit;
    },
    changeWebsite(item) {
      this.showwebsite = item
      this.showEngines = false
    },
    // 首页 背景
    getBanner() {
      this.post('Activity/index').then(res => {
        console.log(res);
        if (res.code == 200) {
          res.data.adv.filter(item => {
            item.image = this.config.config + item.pic
            return item
          })
          this.bannerList = res.data

        } else {
          this.$message.error(res.msg);
        }
      })
    },
    // 页面跳转
    goLink(item) {
      console.log(item);
      if (item.link) {
        let data = { id: item.id }
        this.post('Activity/incClick', data).then(res => {
          if (res.code == 200) {
            var win = window.open(item.link, '_blank');
            win.focus();
          }
        })
      } else {
        this.$message.error('跳转失败');
      }
    },
    titlehandleScroll(event) {
      console.log(event, 'eee');
    }
  },
  destroyed() {
    clearInterval()
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  background-color: rgb(233, 238, 242);
  height: calc(100vh - 64px);
  box-sizing: border-box;
}


.sidebar {
  width: 140px;
  padding: 20px;
  /* 左侧宽度 */
  // border-right: 1px solid #ccc;

  overflow-y: auto;
  // height: calc(100vh - 110px);
  scrollbar-width: none;

  /* 对于Firefox */
  li {
    width: 140px;
    margin: 0.5rem auto;
    padding: 0.5rem 0 0.5rem 1.5rem;
    box-sizing: border-box;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    // justify-content: center;
    text-align: left;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px
    }

    &:hover {
      background: #fff;
    }
  }

  li.active {
    color: #fff;
    background: #0e172c;
  }

}

.sidebar::-webkit-scrollbar {
  // display: none;
  /* 对于Chrome, Safari和Opera */
}

li {
  cursor: pointer;
  list-style: none;
}


.content {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  /* 根据实际情况设置高度 */
  padding-top: 1rem;
  // width: calc(100vw-140px);
  padding: 1rem 1.2rem 2rem 1.2rem;

  .topBox {
    // background-image: url('../image/background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background: rebeccapurple; 
    // overflow: hidden;
    position: relative;

    .bgimg {
      position: absolute;
      width: 100%;
      top: -50%;
      left: 0;
      z-index: 1;
    }

    padding: 6rem 0;
    min-width: 10px;

    .topContent {
      // width: 10%;
      // background: rebeccapurple;
      max-width: 50%;
      margin: 0 auto;
      min-width: 200px;
      color: #fff;

      .time {
        font-size: 3.75rem;
      }

      .dataTime {
        font-size: 0.875rem;

        span {
          margin-right: 1rem;
        }
      }

      .search {
        margin: 10px auto;

        .w-full {
          width: 100%;
        }

        .h-full {
          height: 100%;
        }

        .flex {
          color: #0e172c;
          display: flex;

          .leftcontent {
            background: #eef2f6;

            .engine {
              display: flex;
              align-items: center;
              padding-left: 1rem;
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
              cursor: pointer;

              .flex-shrink-0 {
                flex-shrink: 0;

                img {
                  width: 20px;
                  height: 20px;

                }
              }
            }

            .relative {
              position: relative;

              .engines {
                position: absolute;

                ul {
                  display: flex;
                  flex-direction: column;
                  margin-top: 0.25rem;
                  padding-top: 0.375rem;
                  border-radius: 0.375rem;
                  width: 8rem;
                  background-color: #f5f9fc;

                  li {
                    list-style: none;
                    margin-bottom: 0;
                    padding-left: 0;

                    .flex {
                      display: flex;
                      align-items: center;
                      padding-left: 1rem;
                      padding-top: 0.5rem;
                      column-gap: 8px;
                      font-size: 0.75rem;

                      img {
                        width: 20px;
                        height: 20px;

                      }

                      span {
                        font-size: 0.875rem;
                      }
                    }
                  }
                }
              }
            }

          }

          .flex-grow-1 {
            flex-grow: 1;

            input {
              padding-left: 0.625rem;
              font-size: 0.875rem;
              background-color: #f5f9fc;
              outline: none !important;
              border: 1px;
            }
          }

          .rightcontent {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;

            .btn {
              display: inline;
              border: 1px solid transparent;
              line-height: 1.15;
              vertical-align: middle;
              white-space: nowrap;
              outline: 0;
              user-select: none;
              cursor: pointer;
              text-decoration: none;
              transition-duration: 0.25s;
              transition-property: color, background-color, border-color, box-shadow;
            }

            .btn-3-md {
              padding: 0.75rem 1.5rem;
            }

            .btn-primary {
              background: #0e172c;
              border-color: #0e172c;
              color: #fff;
            }
          }
        }
      }

      .Hotsearch {
        display: flex;

        li:first-child {
          flex: 0 0 50px;
        }

        li {
          font-size: 0.75rem;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;

          .text {
            font-size: 0.75rem;
            text-align: center;
            padding: 5px 8px;
            margin-right: 5px;
            background: rgba(51, 51, 51, 0.1);
            border-radius: 6px;
            white-space: nowrap;

            &:hover {
              color: rgba(51, 51, 51);
              background: #fff;
            }
          }


        }

        li:last-child {
          max-width: 100%;
          // background: rebeccapurple; 
          overflow: hidden;
        }

      }
    }

  }
}

.section {
  // height: 300px;
  /* 每个section的高度 */
  // border-bottom: 1px solid #eee;
  padding: 0.5rem 0;
  text-align: left;
  margin-bottom: 1rem;
}

.list {
  column-gap: 18px;
  margin-top: 14px;
  display: grid;
  // grid-template-columns: repeat(5, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));

  li {
    margin-bottom: 1rem;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    color: #828894;
    background: rgb(245, 249, 252);

    .info {
      // width: 100%;
      // padding: 1rem;
      // box-sizing: border-box;
      // border-radius: 0.5rem;
      // color: #828894;
      // background: rgb(245, 249, 252);

      .title {
        display: flex;
        align-items: center;
        color: #0E172C;
        font-weight: bold;
        font-size: 0.835rem;
        margin-bottom: 0.4rem;
      }

      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 50%;
      }

      p {
        font-size: 0.78rem;
        word-wrap: break-word;
      }

    }

    &:hover {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    }
  }

}


@media screen and (max-width : 1024px) {
  .sidebar {
    display: none;
  }

  .list {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}

@media screen and (max-width : 1552px) {
  .list {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}

@media screen and (max-width : 1274px) {
  .list {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media screen and (max-width : 935px) {
  .list {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}

@media screen and (max-width : 759px) {
  .list {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media screen and (max-width : 572px) {
  .list {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (max-width : 383px) {
  .list {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
</style>