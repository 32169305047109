import Vue from 'vue';
import Router from 'vue-router';

// 引入组件
import HomePage from '../page/HomePage.vue';
import AboutPage from '../components/briefIntroduction.vue';
import photograph from "../page/photograph";
import eCommerce from "../page/eCommerce";
import software from "../page/software";
import AIGG from "../page/AIGG";
import photoDetailes from "../components/photoDetailes";
// import NotFoundPage from './components/NotFoundPage.vue';

// 使用vue-router插件
Vue.use(Router);

// 创建Router实例并配置路由
export default new Router({
    mode: 'history', // 使用HTML5 History模式
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomePage,
        }, {
            path: '/photograph',
            name: 'photograph',
            component: photograph,
        },
        {
            path: '/about',
            name: 'about',
            component: AboutPage,
        },
        {
            path: '/eCommerce',
            name: 'eCommerce',
            component: eCommerce,
        },
        {
            path: '/AIGG',
            name: 'AIGG',
            component: AIGG,
        },
        {
            path: '/software',
            name: 'software',
            component: software,
        },
        {
            path: '/photoDetailes',
            name: 'photoDetailes',
            component: photoDetailes,
        },

        // {
        //   path: '*',
        //   component: NotFoundPage
        // }
    ]
});