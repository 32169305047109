<template>
  <div class='app'>
    <header>
      <div class="topBox">
        <img :src="info.logoImg||''" alt="">
        <img src="../image/daohanglan.png" alt="" v-if="pageWidth < 767" class="icon"
          @click="showNavigation = !showNavigation">
        <ul class="navlist" v-else>
          <li v-for="(item, i) in navList" :key="i" :style="{ background: active == i ? $colors.primary1 : '' }"
            @click="changeNav(item, i)">
            <!-- <router-link :to="item.link" active-class="activeStyle" tag="span">{{ item.name }} </router-link> -->

            {{ item.name }}
          </li>
          <li class="iconBox">
            <span v-for="(item, i) in typeList" :key="i" @click="goLoad(item)">
              <i class="iconfont" :class="item.name"></i>
            </span>
          </li>
        </ul>
      </div>
      <ul class="navlist" v-if="showNavigation">
        <li v-for="(item, i) in navList" :key="i" :style="{ background: active == i ? $colors.primary1 : '' }"
          @click="changeNav(item, i)">
          <!-- <router-link :to="item.link" active-class="activeStyle" tag="span">{{ item.name }} </router-link> -->
          {{ item.name }}
        </li>
        <li class="iconBox">
          <span v-for="(item, i) in typeList" :key="i" @click="goLoad(item)">
            <i class="iconfont" :class="item.name"></i>
          </span>
        </li>
      </ul>
    </header>
    <main>


    </main>
  </div>
</template>

<script>
export default {
  name: 'NavigationPage',
  components: {
  },
  data() {
    return {
      pageWidth: 0,
      navList: [
        {
          index: 0,
          name: "首页",
          link: "/",
        },
        {
          index: 1,
          name: "商业摄影",
          link: "/photograph",
        },
        {
          index: 2,
          name: "电商全案",
          link: "/eCommerce",
        },
        {
          index: 3,
          name: "软件",
          link: "/software",
        },
        {
          index: 4,
          name: "AIGC",
          link: "/AIGG",
        },
        {
          index: 5,
          name: "工作室",
          link: "/about",
        },
      ],
      showNavigation: false,
      typeList: [
        {
          name: 'icon-weibo'
        },
        {
          name: 'icon-xiaoxi'
        },
        {
          name: 'icon-qq'
        }
      ],
      active: sessionStorage.getItem('active') || 0,
      info: {}
    };
  },
  mounted() {
    this.pageWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
    this.getDate()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.pageWidth = window.innerWidth;
    },
    changeNav(item, index) {
      sessionStorage.setItem('active', index)
      this.active = index
      this.showNavigation = false
      this.$router.push(item.link);
    },
    getDate() {
      this.post('Config/base').then(res => {
        console.log(res, 'lis222t');
        if (res.code == 200) {
          this.typeList = [
            {
              name: 'icon-weibo',
              link: res.data.weibo_link || ''
            },
            {
              name: 'icon-xiaoxi',
              link: res.data.wechat_link || ''
            },
            {
              name: 'icon-qq',
              link: res.data.qq_link || ''
            }
          ]
          res.data.logoImg = this.config.config + res.data.logo
          this.info = res.data 
          this.webInfo=res.data
          console.log(this.webInfo,'webInfo',);
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    goLoad(item) {
      console.log(item);
      if (item.link) {
        this.post('Activity/incClick').then(res => {
          if (res.code == 200) {
            var win = window.open(item.link, '_blank');
            win.focus();
          }
        })
      } else {
        this.$message.error('跳转失败');
      }
    },
  }
}
</script>
<style lang='less' scoped>
header {
  width: 100%;
  background: rgb(14, 23, 44);
  color: #fff;
  padding: 1em;
  text-align: center;
  box-sizing: border-box;
  height: 64px;
  // position: fixed;

  position: -webkit-sticky;
  /* 针对Safari浏览器的兼容性 */
  position: sticky;
  top: 0;
  z-index: 10;

  /* 确保sticky元素在最上层 */
  .topBox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 52px;
    }

    .icon {
      width: 30px;
      height: 30px;
    }


  }

  .navlist {
    list-style: none;

    li {
      margin: 10px 0;
      padding: 0.4rem 1rem;
      border-radius: 2rem;
      text-align: center;
      font-size: 15px;
    }

    li:hover {
      cursor: pointer;
    }

    .iconBox {

      // display: flex;
      // border: 1px solid red;
      i {
        margin: 0 10px;
      }
    }
  }

}

.container-wrapper {
  overflow: hidden
}

main {
  max-height: calc(100vh-64px);
  // margin-top: 64px;
  // overflow-y: auto;
  // scrollbar-width: none;
  /* 对于Firefox */
  // padding-top: 80px;
}

main::-webkit-scrollbar {
  // display: none;
  /* 对于Chrome, Safari和Opera */
}

/* 当屏幕最小宽度为992px */
@media screen and (min-width : 767px) {
  header {
    .topBox {
      // background: rebeccapurple;
      justify-content: space-around;

      .navlist {
        li {
          display: inline;

          span {
            font-size: 0.834rem !important;
          }
        }
      }

    }
  }
}

/*  当屏幕最大宽度为767px  */
@media screen and (max-width:767px) {
  header {
    // font-size: 1rem ;

    .navlist {
      background: rgb(14, 23, 44);
      width: 100% !important;
      position: absolute;
      left: 0;
      padding: 1rem;
      box-sizing: border-box;

      li {
        max-width: 150px;
        margin: 0 auto;
      }
    }
  }
}

.activeStyle {
  color: #fff;
  text-decoration: none;
}
</style>