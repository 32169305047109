<template>
    <div class='container'>
        <div class="tabBox" v-if="showTag">
            <ul>
                <li v-for="(item, index) in tabList" :key="index" @click="changeTab(index, item)" :style="{
                    borderColor: active == index ? '#333' : ''
                }">{{ item.title }}</li>
            </ul>
            <div class="line"></div>
        </div>
        <div class="contentBox" v-for="(item, index) in list" :key="index">
            <div v-if="!showTag" style="margin-top: 28px;margin-bottom: 40px;">
                <h3 class="title">{{ item.title || '' }}</h3>
                <div class="introduce">{{ item.intro || '' }}</div>
            </div>
            <ul class="itemBox" :style="{
                marginBottom: pageType == 'photograph' ? '60px' : ''
            }">
                <li class="item" v-for="(info, i) in item.list" :key="i" @mouseenter="showMasker(i)"
                    @click="getDetailes(info)">
                    <img :src="info.image" alt="">
                    <div class="mask">
                        <span class="text-drop" v-if="!showTag">{{ info.title }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ImageAndText',
    components: {},
    data() {
        return {
            active: 0,
            activeItem: null,
            maskText: '',
        };
    },
    props: {
        showTag: {
            type: Boolean,
            default: false
        },
        pageType: {
            type: String,
            default: ""
        },
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        tabList: {
            type: Array,
            default: () => {
                return []
            }
        },

    },
    methods: {
        showMasker(index) {
            this.activeItem = index
            // console.log(123, index);
        },
        changeTab(index, item) {
            this.active = index
            this.$emit('changeTab', index, item)
        },
        getDetailes(item) {
            if (!this.showTag) {
                // this.post('Photo/photographyInfo', {
                //     id: item.id
                // }).then(res => {
                //     console.log(res, 'resresres');
                // })
                this.$router.push(`/photoDetailes?id=${item.id}`)
            } else {
                // this.post('Aicg/getDetail', {
                //     id: item.id
                // }).then(res => {
                //     console.log(res, 'resresres');
                // })
                this.$router.push(`/photoDetailes?id=${item.id}&&type=2`)
            }
        }
    },
}
</script>
<style lang='less' scoped>
.container {
    padding: 20px;
    // max-width: 1500px;
    max-width: 1280px;
    margin: 0 auto;

    li {
        list-style: none;
    }

    .contentBox {
        .title {
            font-size: 1.75rem;
        }

        .introduce {
            font-size: 1.0625rem;
            margin: 0.25rem 0.4rem;
        }



        .itemBox {
            column-gap: 32px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            margin: 0 auto;
            margin-top: 24px;

            li {
                position: relative;
                margin-bottom: 14px;
                max-height: 560px;

                img {
                    width: 100%;
                }

                .mask {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 99%;
                    background: rgba(0, 0, 0, 0);
                    // background: yellow;
                    // display: flex;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    animation: changeBack 0.5s infinite;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;

                    .text-drop {
                        animation: scaleSize 0.8s infinite;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                    }
                }
            }

            li:hover .mask {
                display: flex;
                // background: red;

            }
        }

    }
}

.tabBox {
    position: relative;
    font-size: 0.875rem;
    color: #636b79;

    ul {
        display: flex;

        li {
            margin: 10px;
            padding: 10px 0;
            border-bottom: 2px solid transparent;
            z-index: 2;
        }
    }

    .line {
        width: 100%;
        border-bottom: 2px solid #d7dce1;
        position: absolute;
        bottom: 10px;
        z-index: 1;
    }
}

@keyframes scaleSize {

    0%,
    100% {
        // transform: scale(0.8);
        transform: translateY(0);
    }

    50% {
        // transform: scale(1);
        transform: translateY(2px);
    }
}

@media screen and (max-width : 1023px) {
    .itemBox {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

@media screen and (max-width : 767px) {
    .itemBox {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@keyframes changeBack {
    from {
        background: rgba(0, 0, 0, 0);
    }

    to {
        background: rgba(0, 0, 0, 0.6);
    }
}
</style>